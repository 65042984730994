<template>
    <div>
        <div class="flex-page-content flex-container">
            <div class="flex-body body-scroll">
                <div class="container-fluid">

                    <div v-if="mode == 'setup'">
                        <div class="container-centered my-4">
                            <div class="row">
                                <div class="col-12 col-md-8 offset-md-2">
                                    <h3>Import Time Cards <span v-if="pos_do_new_hires">and New Hires</span></h3>

                                    <p>
                                        Select the settings for this Point of Sale import.
                                    </p>

                                    <div class="alert alert-warning">
                                        <i class="fas fa-fw fa-exclamation-triangle"></i>
                                        Re-importing a payroll for the same dates will duplicate data. If you’ve made a mistake and need to re-import, contact Paymasters for assistance.
                                    </div>

                                    <form @submit.prevent="goToPreview" novalidate class="mt-4">
                                        <form-errors :errors="formErrors" :errorsHtml="formErrorsHtml" />

                                        <div class="row">
                                            <div class="col-12">
                                                <form-input label="integration" v-model="formData.integration" :errors="errors.integration" type="select" :options="integrationOptions"/>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-6">
                                                <form-input label="Start Date" v-model="formData.date_start" :errors="errors.date_start" type="native-date" />
                                            </div>
                                            <div class="col-6">
                                                <form-input label="End Date" v-model="formData.date_end" :errors="errors.date_end" type="native-date" />
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-6">
                                                <form-input label="Pay Group" v-model="formData.paygroup" :errors="errors.paygroup" type="select" :options="paygroupOptions"/>
                                            </div>
                                            <div class="col-6">
                                                <form-input label="Store" v-model="formData.store" :errors="errors.store" type="select" :options="storeOptions"/>
                                            </div>
                                        </div>

                                        <div class="row mb-2" v-if="selectedPOS == 'R365'">
                                            <div class="col-12">
                                                <form-input type="file" v-model="formData.pos_file" :errors="errors.pos_file" label="Upload CSV (optional - replaces POS login)" helper-text="File extension must be .csv" accept=".csv" @upload="onUpload($event)" />
                                            </div>
                                        </div>

                                        <div class="row mb-2 d-flex justify-content-between">
                                            <div class="col">
                                                <button type="button" class="btn btn-block btn-outline-primary" @click="goBack()">Back</button>
                                            </div>
                                            <div class="col">
                                                <button type="submit" class="btn btn-block btn-primary">Continue</button>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="mode == 'preview'">
                        <div class="py-2 border-bottom">
                            <div class="row row-alt align-items-center">
                                <div class="col-auto">
                                    <div class="d-flex flex-wrap py-1">
                                        <div class="mr-4">
                                            <div class="theading">
                                                Pay Group
                                            </div>
                                            <div class="text-large text-semibold mr-2">
                                                {{ displayPaygroup }}
                                            </div>
                                        </div>
                                        <div class="mr-4">
                                            <div class="theading">
                                                Store
                                            </div>
                                            <div class="text-large text-semibold mr-2">
                                                {{ displayStore }}
                                            </div>
                                        </div>
                                        <div class="mr-0">
                                            <div class="theading">
                                                Pay Period
                                            </div>
                                            <div class="text-large text-semibold mr-2">
                                                <span class="text-thin">
                                                    {{ displayDateStartDOW }}
                                                </span>
                                                {{ displayDateStart }}
                                                <span class="text-thin">
                                                     &ndash; {{ displayDateEndDOW }}
                                                </span>
                                                {{ displayDateEnd }}
                                            </div>
                                        </div>

                                        <div class="mr-4" v-if="showLocationsStatus && store_data_status && store_data_status.length">
                                            <div class="theading">
                                                Hours Posted
                                            </div>
                                            <div class="text-large text-semibold mr-2">
                                                {{ locationsStatus.good.length }}/{{ store_data_status.length }} Locations

                                                <a href v-if="locationsStatus.bad.length" class="badge badge-primary ml-hf" @click.prevent="POSIntegrationStoresModalOpen = true">
                                                    {{ locationsStatus.bad.length }}
                                                </a>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="col text-md-right">
                                    <div class="py-1">
                                        <div class="step-items">
                                            <div class="step-item step-item-active">
                                                <div class="step-item-number">
                                                    1
                                                </div>
                                                <div class="step-item-label">
                                                    Start<br>
                                                    Import
                                                </div>
                                            </div>
                                            <div class="step-item ">
                                                <div class="step-item-number">
                                                    2
                                                </div>
                                                <div class="step-item-label">
                                                    Review<br>
                                                    Issues
                                                </div>
                                            </div>
                                            <div class="step-item ">
                                                <div class="step-item-number">
                                                    3
                                                </div>
                                                <div class="step-item-label">
                                                    Final<br>
                                                    Review
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="flex-body body-scroll pb-2">
                            <div class="pt-4 text-center">
                                <p class="lead text-semibold mb-3">
                                    Employee hours will be imported from your Point-of-Sale system.
                                </p>

                                <div class="w-20r mx-auto mb-2">
                                    <a href @click.prevent="startPOSImport" class="module-tile module-tile-pos position-relative mb-2">
                                        <div class="module-tile-inner">
                                            <div class="module-tile-content">
                                                <span class="module-tile-icon" style="margin-top: -2.5rem; font-size: 6.5rem;">
                                                    <i class="fal fa-fw fa-cogs"></i>
                                                </span>
                                                <div class="module-tile-label" style="top: 13.0rem;">
                                                    <span class="lead text-primary text-semibold">
                                                        Import Hours <i class="far fa-chevron-right ml-1" style="font-size: 90%;"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div class="mx-auto text-center">
                                    <a href @click.prevent="mode = 'setup'">Back to start</a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="mode == 'pending'">
                        <div class="py-2 border-bottom">
                            <div class="row row-alt align-items-center">
                                <div class="col-auto">
                                    <div class="d-flex flex-wrap py-1">
                                        <div class="mr-4">
                                            <div class="theading">
                                                Pay Group
                                            </div>
                                            <div class="text-large text-semibold mr-2">
                                                {{ displayPaygroup }}
                                            </div>
                                        </div>
                                        <div class="mr-4">
                                            <div class="theading">
                                                Store
                                            </div>
                                            <div class="text-large text-semibold mr-2">
                                                {{ displayStore }}
                                            </div>
                                        </div>
                                        <div class="mr-0">
                                            <div class="theading">
                                                Pay Period
                                            </div>
                                            <div class="text-large text-semibold mr-2">
                                                <span class="text-thin">
                                                    {{ displayDateStartDOW }}
                                                </span>
                                                {{ displayDateStart }}
                                                <span class="text-thin">
                                                     &ndash; {{ displayDateEndDOW }}
                                                </span>
                                                {{ displayDateEnd }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col text-md-right">
                                    <div class="py-1">
                                        <div class="step-items">
                                            <div class="step-item step-item-active">
                                                <div class="step-item-number">
                                                    1
                                                </div>
                                                <div class="step-item-label">
                                                    Start<br>
                                                    Import
                                                </div>
                                            </div>
                                            <div class="step-item ">
                                                <div class="step-item-number">
                                                    2
                                                </div>
                                                <div class="step-item-label">
                                                    Review<br>
                                                    Issues
                                                </div>
                                            </div>
                                            <div class="step-item ">
                                                <div class="step-item-number">
                                                    3
                                                </div>
                                                <div class="step-item-label">
                                                    Final<br>
                                                    Review
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="flex-body pb-2">
                            <br><br><br><br>
                            <div class="text-center">
                                <div class="mx-auto d-flex flex-column justify-content-center">
                                    <div class="spinner mx-auto position-relative mb-2">
                                        <div class="lds-grid">
                                            <div></div><div></div><div></div>
                                            <div></div><div></div><div></div>
                                            <div></div><div></div><div></div>
                                        </div>
                                    </div>
                                    <div class="position-relative mx-auto">
                                        <h4>Getting Data</h4>
                                        <ul class="list-unstyled process-spinner-messages">
                                            <li class="d-block">
                                                {{ statusDetail }}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <pos-integration-stores-modal
            :stores="locationsStatus.bad"
            v-if="locationsStatus.bad.length && POSIntegrationStoresModalOpen"
            @close="POSIntegrationStoresModalOpen = false"
        />
    </div>
</template>

<script>
import FormMixin from '@/mixins/Form'
import api from '@/api'
import POSIntegrationStoresModal from './POSIntegrationStoresModal'

import moment from 'moment'

export default {
    props: ['client'],
    components: {PosIntegrationStoresModal: POSIntegrationStoresModal},
    mixins: [FormMixin, ],
    data() {
        return {
            mode: 'setup',
            paygroupOptions: [],
            integrationOptions: [],
            storeOptions: [],
            store_data_status: [],
            pos_do_new_hires: false,
            POSIntegrationStoresModalOpen: false,
            statusDetail: "Syncing data from POS",
            formRules: {
                integration: {
                    presence: {allowEmpty: false},
                },
                date_start: {
                    presence: {allowEmpty: false},
                },
                date_end: {
                    presence: {allowEmpty: false},
                },
                paygroup: {
                    presence: {allowEmpty: false},
                },
                store: {
                    presence: {allowEmpty: false},
                },

            },
            formLabels: {
            },
            formErrorsHtml: [],
            formData: this.makeFormData(),
        }
    },
    mounted() {
        this.$store.dispatch('SET_PAGE_TITLE', 'Point-of-Sale Integration')
        this.getInitialData()
    },
    computed: {
        displayPaygroup() {
            if (this.formData.paygroup == '*') {
                return 'ALL'
            }

            return this.formData.paygroup
        },
        displayStore() {
            if (this.formData.store == '*') {
                return 'ALL'
            }

            return this.formData.store
        },
        displayDateStart() {
            let value = this.formData.date_start
            let date_format = 'MM/DD/YYYY' ? value.includes('/') : 'YYYY-MM-DD'
            let date_moment = value ? moment(value, date_format) : null
            if (date_moment) {
                return date_moment.format('MM/DD/YYYY')
            }
            return ''
        },
        displayDateEnd() {
            let value = this.formData.date_end
            let date_format = 'MM/DD/YYYY' ? value.includes('/') : 'YYYY-MM-DD'
            let date_moment = value ? moment(value, date_format) : null
            if (date_moment) {
                return date_moment.format('MM/DD/YYYY')
            }
            return ''
        },
        displayDateStartDOW() {
            let value = this.formData.date_start
            let date_format = 'MM/DD/YYYY' ? value.includes('/') : 'YYYY-MM-DD'
            let date_moment = value ? moment(value, date_format) : null
            if (date_moment) {
                return date_moment.format('ddd')
            }
            return ''
        },
        displayDateEndDOW() {
            let value = this.formData.date_end
            let date_format = 'MM/DD/YYYY' ? value.includes('/') : 'YYYY-MM-DD'
            let date_moment = value ? moment(value, date_format) : null
            if (date_moment) {
                return date_moment.format('ddd')
            }
            return ''
        },
        selectedPOS() {
            if (! this.formData.integration) {
                return ''
            }

            let opt = this.integrationOptions.filter(o => o.value == this.formData.integration)
            if (! opt) {
                return ''
            }

            return opt[0].pos
        },
        showLocationsStatus() {
            return (this.selectedPOS == 'lc_alt_api')
        },
        locationsStatus() {
            if (! this.store_data_status) {
                return {}
            }
            return {
                'good': this.store_data_status.filter(o => o.status == "Ready"),
                'bad': this.store_data_status.filter(o => o.status != "Ready"),
            }
        },
    },
    methods: {
        makeFormData() {
            return {
                'integration': '',
                'store': '*',
                'paygroup': '*',
                'date_start': '',
                'date_end': '',
                'pos_file': null,
            }
        },
        onUpload(f) {
            this.formData.pos_file = f[0]
        },
        getInitialData() {
            this.dataLoaded = false
            this.$store.dispatch('START_LOADING')

            let url = `/clients/${this.client.id}/pos-integration`

            let urlParams = new URLSearchParams(window.location.search);
            let initial_task_id = urlParams.get('initial');
            if (initial_task_id) {
                url += '?initial=' + initial_task_id
            }

            this.$api.get(url).then(resp => {
                this.paygroupOptions = resp['paygroups']
                this.integrationOptions = resp['pos_integrations']
                this.storeOptions = resp['stores']
                this.pos_do_new_hires = resp['pos_do_new_hires']

                // if there's only one option, pick that one
                if (this.integrationOptions.length == 1) {
                    this.formData.integration = this.integrationOptions[0].value
                }

                if (this.paygroupOptions.length == 1) {
                    this.formData.paygroup = this.paygroupOptions[0].value
                }

                if (this.storeOptions.length == 1) {
                    this.formData.store = this.storeOptions[0].value
                }

                if (resp.initial_data) {
                    for (const [k, v] of Object.entries(resp.initial_data)) {
                        this.formData[k] = v
                    }
                }

                this.dataLoaded = true
                this.$store.dispatch('STOP_LOADING')
            }).catch((errors) => {
                if (errors.__status__ == 403) {
                    this.$store.dispatch('STOP_LOADING')
                    this.$bus.$emit('no-access')
                    return
                }

                this.$store.dispatch('STOP_LOADING')
                this.$bus.showError(errors.__all__[0])
            })
        },
        startPOSImport() {
            const url = `/clients/${this.client.id}/pos-integration/start`
            this.mode = 'pending'

            if (this.formData.pos_file) {
                return this.$api.upload(url, this.formData.pos_file, {'formData': this.formData, 'fieldName': 'pos_file'}).then(resp => {
                    let taskId = resp['task']['id']
                    this.$router.push({name: 'pos-integration-status', params: {hashedClientId: this.$route.params.hashedClientId, taskId: taskId}});
                }).catch(errors => {
                    this.errors = errors
                    this.formErrors = errors.__all__
                    this.mode = 'setup'
                    this.$bus.showError(errors.__all__)
                })
            } else {
                return this.$api.post(url, this.formData).then(resp => {
                    let taskId = resp['task']['id']
                    this.$router.push({name: 'pos-integration-status', params: {hashedClientId: this.$route.params.hashedClientId, taskId: taskId}});
                }).catch(errors => {
                    this.errors = errors
                    this.formErrors = errors.__all__
                    this.mode = 'setup'
                    this.$bus.showError(errors.__all__)
                })
            }
        },
        reset: function() {
            this.errors = {}
            this.formErrorsHtml = []
            this.formData = this.makeFormData()
        },
        goToPreview: function() {
            if (!this.validate()) {
                return
            }

            const url = `/clients/${this.client.id}/pos-integration/preview-data`
            this.mode = 'preview'

            return this.$api.post(url, this.formData).then(resp => {
                this.store_data_status = resp['store_data_status']
            }).catch(errors => {
                this.errors = errors
                this.formErrors = errors.__all__
                this.mode = 'setup'
                this.$bus.showError(errors.__all__)
            })

        },
        goBack: function() {
            this.$router.push({name: 'employer-home', params: {hashedClientId: this.$route.params.hashedClientId}});
        }
    },
}
</script>
